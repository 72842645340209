const menuWrapper = document.querySelector("[data-menu-container]");
const mainMenu = document.querySelector("[data-main-menu]");
const minWidth = mainMenu ? parseInt(mainMenu.dataset.breakpoint) : 0;

export function initNavigation() {
	/**
	 * @title Menu Trigger
	 **/
	const menuTrigger = document.querySelector("[data-menu-open]");
	const menuClose = document.querySelectorAll("[data-menu-close]");

	if (mainMenu) {
		// Activate the Keyboard nav on keydown
		mainMenu.addEventListener("keydown", keyboardNavigation, false);

		// Lets add an attribute to the menu so we can add focus outlines
		document.body.addEventListener("keydown", function (event) {
			if (event.keyCode === 9) {
				document.body.setAttribute("data-keyboard-user", "");
			}
		});

		// Check the trigger exists
		if (menuTrigger) {
			menuTrigger.addEventListener("click", menuButtonHandler.bind(event, "open"));
		}

		// Check the close button exists
		if (menuClose) {
			menuClose.forEach(function (close) {
				close.addEventListener("click", menuButtonHandler.bind(event, "close"));
			});
		}
	}

	/**
	 * @title Menu Mouse Navigation
	 **/
	const menuLinks = mainMenu ? mainMenu.querySelectorAll("[data-nav-item-children] > a") : null;

	/**
	 * @description Opening Submenus
	 */
	if (menuLinks && menuLinks.length) {
		menuLinks.forEach(function (link) {
			link.addEventListener("click", linkEvent, false);
		});

		// Close the menu if we click outside of it
		window.addEventListener("click", windowClickClose, false);
	}

	function addAttributes(item) {
		const child = item.querySelector("[data-submenu]");
		if (child) {
			child.setAttribute("data-menu", "open");
			child.setAttribute("aria-hidden", "false");
			item.setAttribute("data-nav-item-children", "open");
			item.setAttribute("aria-expanded", "true");
		}
	}

	function removeAttributes(item) {
		const child = item.querySelector("[data-submenu]");
		if (child) {
			child.setAttribute("data-menu", "");
			child.setAttribute("aria-hidden", "true");
			item.setAttribute("data-nav-item-children", "");
			item.setAttribute("aria-expanded", "false");
		}
	}

	/**
	 * @description If the menu is set to slide, this will add a button to go back to the previous menu
	 */
	const backButtons = mainMenu ? mainMenu.querySelectorAll("[data-menu-target]") : null;
	if (backButtons && backButtons.length) {
		backButtons.forEach(function (button) {
			button.addEventListener("click", backButton, false);
		});
	}

	/**
	 * @title Navigation controller
	 * @description Based on key input decide what actions should be taken to control
	 * menu
	 *
	 * @param {event} event
	 */
	function keyboardNavigation(event) {
		const activeElement = document.activeElement;
		const parentNode = activeElement.parentNode;
		const menuID = parentNode.dataset.menuId;
		const subMenu = activeElement.closest("[data-submenu]");

		switch (event.key) {
			case "Escape":
				// Check if this item is in a submenu or not
				if (subMenu) {
					closeSubMenu(subMenu, menuID);
				} else {
					closeNavigation();
				}
				break;
			case "ArrowLeft":
				event.preventDefault();
				keyboardDirection(parentNode, "left");
				break;
			case "ArrowUp":
				event.preventDefault();
				keyboardDirection(parentNode, "up");
				break;
			case "ArrowRight":
				event.preventDefault();
				keyboardDirection(parentNode, "right");
				break;
			case "ArrowDown":
				event.preventDefault();
				keyboardDirection(parentNode, "down");
				break;
			case "Tab":
				if (event.shiftKey) {
					keyboardDirection(parentNode, "left");
				} else {
					keyboardDirection(parentNode, "tab");
				}
				break;
			default:
				return;
		}
	}

	//-- Functions --//

	/**
	 * @title Menu Button Handler
	 * @description This will control the opening & closing of the menu.
	 * @param {string} action - open/close
	 */
	function menuButtonHandler(action) {
		switch (action) {
			case "open":
				menuWrapper.setAttribute("data-menu-container", "open");
				openNavigation(null, null, mainMenu, null, true);
				menuTrigger.setAttribute("aria-expanded", "true");
				break;
			case "close":
				closeNavigation();
				menuTrigger.setAttribute("aria-expanded", "false");
				break;
			default:
				return;
		}
	}

	/**
	 * @title Menu Links
	 * @description This will control the opening & closing of a submenu.
	 */
	function linkEvent() {
		event.preventDefault();

		let parentNode = this.parentNode;

		if (this.tagName === "DIV") {
			parentNode = this;
		}

		const parentLink = parentNode.getElementsByTagName("a")[0];

		// Get all the siblings of our parent
		const siblings = getSiblings(parentNode);

		const parentID = parentNode.dataset.id;
		const previousMenuID = parentNode.dataset.menuId;
		const previousMenu = document.querySelector('[data-menu-id="' + previousMenuID + '"]');
		const submenu = document.querySelector('[data-menu-id="' + parentID + '"]');

		siblings.forEach(function (sibling) {
			const siblingSubmenu = sibling.querySelector("[data-submenu]");
			const siblingLink = sibling.querySelector("[aria-expanded]");
			sibling.setAttribute("data-nav-item-children", "");
			siblingLink.setAttribute("aria-expanded", "false");
			siblingSubmenu.setAttribute("data-menu", "");
			siblingSubmenu.setAttribute("aria-hidden", "true");
			return;
		});

		const eventType = event.type;

		openNavigation(parentNode, parentLink, submenu, previousMenu, true);

		return;
	}

	/**
	 * @title Window Click Close
	 * @description Close the submenu if you click anywhere else on the page
	 */
	function windowClickClose() {
		if (
			document.querySelector('[data-nav-item-children="open"]') &&
			!mainMenu.contains(event.target) &&
			window.matchMedia(`(min-width: ${minWidth}px)`).matches
		) {
			closeNavigation();
		}
	}

	/**
	 * @title Back Button
	 * @description Go back to the Previous Menu
	 * @param {object} button - the button clicked
	 */
	function backButton() {
		const button = event.target;
		const targetMenu = document.querySelector(`[data-menu-id="${button.dataset.menuTarget}"]`);
		const currentMenu = document.querySelector(`[data-menu-id="${button.dataset.menuCurrent}"]`);

		// Get new focus item
		const currentParentItem = document.querySelector(`[data-id="${button.dataset.menuCurrent}"]`);

		const focusElement = currentParentItem.getElementsByTagName("a")[0];

		openNavigation(currentParentItem, focusElement, targetMenu, currentMenu, false, focusElement);
	}

	/**
	 * @title Open Navigation
	 * @description This will open up the mobile menu
	 * @param {object} elementToOpen - The submenu/menu to be opened
	 * @param {object} elementToClose - The submenu/menu to be closed
	 * @param {bool} childLevel - Whether or not we're opening a child submenu
	 * @param {object} focusElement - If there should be a certain focusElement
	 */

	function openNavigation(
		parentNode,
		parentLink,
		elementToOpen,
		elementToClose,
		childLevel,
		focusElement,
	) {
		if (elementToOpen) {
			const openLink = elementToOpen.getElementsByTagName("a")[0];
			// Make sure the body can't scroll when the mobile menu is open
			document.body.setAttribute("data-menu-open", "");

			// Our version of a toggle for the CSS
			const data = elementToOpen.getAttribute("data-menu");
			if ((data && data === "open") || (data && data === "child-open" && childLevel !== false)) {
				elementToOpen.setAttribute("data-menu", "");
				elementToOpen.setAttribute("aria-hidden", "true");
				if (parentNode && parentLink) {
					parentNode.setAttribute("data-nav-item-children", "");
					parentLink.setAttribute("aria-expanded", "false");
				}
				document.body.removeAttribute("data-menu-open");
			} else {
				elementToOpen.setAttribute("data-menu", "open");
				elementToOpen.setAttribute("aria-hidden", "false");
				if (parentNode && parentLink) {
					parentNode.setAttribute("data-nav-item-children", "open");
					parentLink.setAttribute("aria-expanded", "true");
				}
			}

			if (elementToClose && typeof elementToClose !== "undefined") {
				if (typeof childLevel !== "undefined" && childLevel === true) {
					elementToClose.setAttribute("data-menu", "child-open");
				} else {
					elementToClose.setAttribute("data-menu", "closed");
				}
			}

			// Lets shift the focus to the first focusable link once the transition is over
			const firstResult =
				typeof focusElement !== "undefined"
					? focusElement
					: elementToOpen.getElementsByTagName("a")[0];

			const style = window.getComputedStyle(firstResult, null).getPropertyValue("transition");

			if (style === "all 0s ease 0s") {
				firstResult.focus();
			} else {
				firstResult.addEventListener(
					"transitionend",
					// Arrow function?
					function (event) {
						firstResult.focus();
					},
					{
						once: true,
					},
				);
			}
		}
	}

	/**
	 * @title Close Entire Navigation
	 * @description This will check the transitions on the main menu, and then run a function to close the main menu
	 */

	function closeNavigation() {
		document.body.removeAttribute("data-menu-open");
		// Close the main menu
		mainMenu.setAttribute("data-menu", "");

		const style = window.getComputedStyle(mainMenu, null).getPropertyValue("transition");

		// If there is no transition
		if (style === "all 0s ease 0s") {
			closeMenu();
		} else {
			// If a transition is applied, we need to close the menu once it's finished
			mainMenu.addEventListener(
				"transitionend",
				function (event) {
					closeMenu();
				},
				{
					once: true,
				},
			);
		}
	}

	/**
	 * @title Closing Menu
	 * @description This will close the submenus & mobile menu
	 */
	function closeMenu() {
		const firstLevelMenu = document.querySelector('[data-menu-id="1"]');
		const subMenus = document.querySelectorAll("[data-submenu]");
		const menuLinks = mainMenu.querySelectorAll("[aria-expanded='true']");

		// Set the initial menu back to original state
		firstLevelMenu.setAttribute("data-menu", "open");
		firstLevelMenu.setAttribute("aria-hidden", "false");

		// Close all submenus
		if (subMenus.length) {
			subMenus.forEach(function (element) {
				element.setAttribute("data-menu", "");
				element.setAttribute("aria-hidden", "true");
			});
		}

		// Change all link attributes
		if (menuLinks.length) {
			menuLinks.forEach(function (element) {
				element.setAttribute("aria-expanded", "false");
			});
		}
	}

	/**
	 * @title Get Siblings
	 * @description Get the siblings of an element
	 * @param {element} element
	 */
	function getSiblings(element) {
		let siblings = [];
		let sibling = element.parentNode.firstElementChild;

		// Maybe change to array?
		while (sibling) {
			if (
				sibling.nodeType === 1 &&
				sibling !== element &&
				sibling.hasAttribute("data-nav-item-children")
			) {
				siblings.push(sibling);
			}
			sibling = sibling.nextSibling;
		}
		return siblings;
	}

	/**
	 * @title Close Sub Navigation
	 * @description This will close sub menus only
	 * @param {object} activeSubmenu
	 * @param {string} menuID
	 */

	function closeSubMenu(activeSubmenu, menuID) {
		const parentItem = document.querySelector('[data-nav-item][data-id="' + menuID + '"]');
		const parentLink = parentItem.getElementsByTagName("a")[0];

		activeSubmenu.setAttribute("data-menu", "");
		activeSubmenu.setAttribute("aria-hidden", "true");

		parentItem.setAttribute("data-nav-item-children", "");

		parentLink.focus();

		return;
	}

	/**
	 * @title Keyboard Direction
	 * @description This will dictate which direction you move on the menu based on keys
	 * @param {object} element
	 * @param {string} direction
	 */

	function keyboardDirection(element, direction) {
		let previousElement = element.previousElementSibling;
		const nextElement = element.nextElementSibling;
		const submenuTransition = mainMenu.querySelector("[data-submenu-transition]").dataset
			.submenuTransition;
		const isSubmenu = element.closest(["[data-submenu]"]);

		switch (direction) {
			case "left":
				if (previousElement) {
					if (
						previousElement.hasAttribute("data-nav-item") &&
						previousElement.getElementsByTagName("span") &&
						!previousElement.getElementsByTagName("a")[0]
					) {
						previousElement = previousElement.previousElementSibling;
					}
					if (previousElement.hasAttribute("data-nav-item")) {
						previousElement.getElementsByTagName("a")[0].focus();
					}
					if (previousElement.hasAttribute("data-menu-current")) {
						previousElement.focus();
					}
				}
				break;
			case "right":
				if (nextElement && nextElement.hasAttribute("data-nav-item")) {
					nextElement.getElementsByTagName("a")[0].focus();
				}
				break;
			case "down":
				if (
					element.hasAttribute("data-nav-item-children") &&
					element.getAttribute("data-nav-item-children") === "open" &&
					window.matchMedia(`(min-width: ${minWidth}px)`).matches
				) {
					const subMenu = element.querySelector("[data-submenu]");
					const firstResult = subMenu.getElementsByTagName("a")[0];

					firstResult.focus();
				} else if (nextElement && nextElement.hasAttribute("data-nav-item")) {
					let nextLink = nextElement.getElementsByTagName("a")[0];
					const nextHeading = nextElement.getElementsByTagName("span");
					if (nextLink) {
						nextLink.focus();
					} else if (nextHeading && nextElement.nextElementSibling) {
						nextLink = nextElement.nextElementSibling.getElementsByTagName("a")[0];
						if (nextLink) {
							nextLink.focus();
						}
					}
				}
				break;
			case "up":
				if (previousElement) {
					if (
						previousElement.hasAttribute("data-nav-item") &&
						previousElement.getElementsByTagName("span") &&
						!previousElement.getElementsByTagName("a")[0]
					) {
						previousElement = previousElement.previousElementSibling;
					}
					if (
						previousElement &&
						previousElement.hasAttribute("data-nav-item") &&
						previousElement.getElementsByTagName("a")[0]
					) {
						previousElement.getElementsByTagName("a")[0].focus();
					}
					if (previousElement && previousElement.hasAttribute("data-menu-current")) {
						previousElement.focus();
					}
					if (previousElement && previousElement.tagName === "BUTTON") {
						closeSubMenu(isSubmenu, isSubmenu.dataset.menuId);
					}
				}
				break;
			case "tab":
				if (
					submenuTransition == "slide" &&
					window.matchMedia(`(max-width: ${minWidth - 1}px)`).matches &&
					!nextElement &&
					isSubmenu
				) {
					isSubmenu.addEventListener(
						"transitionend",
						function (event) {
							const active = document.activeElement;
							openNavigation(
								element,
								element.getElementsByTagName("a")[0],
								active.closest(["[data-menu]"]),
								isSubmenu,
								false,
								active,
							);
						},
						{
							once: true,
						},
					);
				} else if (nextElement && nextElement.hasAttribute("data-nav-item")) {
					event.preventDefault();
					nextElement.getElementsByTagName("a")[0].focus();
				}
				break;
			default:
				return;
		}
	}

	/**
	 * @title Throttle
	 * @description Custom Throttle function
	 **/
	function throttle(action, timeFrame) {
		let lastTime = 0;
		return function (...args) {
			var now = new Date();
			if (now - lastTime >= timeFrame) {
				action(...args);
				lastTime = now;
			}
		};
	}

	/**
	 * @title Resize Fixes
	 * @description Hides the Mobile Navigation on resize. This stops the animation from running during resize
	 **/
	if (menuTrigger) {
		window.addEventListener(
			"resize",
			throttle(function (e) {
				const menuPanels = document.querySelectorAll("[data-menu]");
				if (
					menuTrigger.getAttribute("aria-expanded") === "false" &&
					window.matchMedia(`(max-width: ${minWidth - 1}px)`).matches
				) {
					menuWrapper.setAttribute("data-menu-container", "closed");
					menuPanels.forEach(function (panel) {
						if (panel.dataset.menu !== "main") {
							panel.dataset.menu = "";
						}
					});
				}
			}, 500),
		);
	}
}
