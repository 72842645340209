export function initHeroVideo() {
	const heroButton = document.querySelector("[data-hero-button]");
	if (heroButton) {
		const heroVideo = document.querySelector("[data-hero-video]");
		const heroContent = document.querySelector("[data-hero-content]");

		console.log(heroContent);

		heroButton.addEventListener("click", function () {
			heroContent.classList.add("opacity-0");

			heroContent.addEventListener(
				"transitionend",
				// Arrow function?
				function (event) {
					heroVideo.classList.remove("invisible");
					heroVideo.classList.add("!opacity-100");
					heroVideo.setAttribute("autoplay", "");
				},
				{
					once: true,
				},
			);
		});
	}
}
