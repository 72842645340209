import * as Utils from "../helpers/utilities";

export function initDropdown() {
	const dropdownItems = document.querySelectorAll("[data-dropdown]");
	const dropdownContentPanes = document.querySelectorAll("[data-dropdown-content]");

	dropdownItems.forEach(function (item) {
		const dropdownHeading = item.querySelector("[data-dropdown-header]");
		dropdownHeading.addEventListener("click", accordionControls);
	});

	function accordionControls(event) {
		dropdownContentPanes.forEach(function (item) {
			const target = event.target.getAttribute("aria-controls");
			const panelID = item.id;

			if (panelID === target) {
				Utils.toggleElement(item);
			}
		});
	}
}
