import { initNavigation } from "./modules/navigation";

import { initDropdown } from "./molecules/dropdown";

import { initVideo } from "./molecules/video";

import { slider } from "./molecules/slider";

import { initHeroVideo } from "./molecules/hero-video";

import { initLeadership } from "./molecules/leadership";

// Load all JS after Content Load
document.addEventListener("DOMContentLoaded", function () {
	initNavigation();
	initDropdown();
	initVideo();
	slider();
	initHeroVideo();
	initLeadership();
});
