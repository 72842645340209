/**
    @title: Lazy Loading
    @observers: {
        - image: Images with srcset data attribute
        - iframe: Iframes with the iframe src data attribute
        - background: backgrounds with the bg data attribute
    }
**/

// Set these variables before the functions so they can be used in multiple places
let leadershipVideoObserver;

/**
    @title: Lazy
    @description: Uses Intersection Observer to check whether or not the item is within the viewport.
		If it is then we need to change the markup so the element is loaded.
**/
function initLeadership() {
	const leadershipVideo = Array.from(document.querySelectorAll("[data-leadership-video]"));

	if ("IntersectionObserver" in window) {
		// Image Observer for loading Images
		leadershipVideoObserver = new IntersectionObserver(function (videos) {
			// Loop through all applicable videos on the page
			videos.forEach(function (video) {
				// Returns a boolean value if the video intersects with the intersection observer's root
				if (video.isIntersecting) {
					// Get the target video
					let target = video.target;

					target.setAttribute("autoplay", "");

					target.onload = () => {
						lazyImageObserver.unobserve(target);
					};
				}
			});
		});

		// Loop through all the images found and run the observer above
		leadershipVideo.forEach(function (videos) {
			leadershipVideoObserver.observe(videos);
		});
	}
}

export { initLeadership };
