/**
 * @title Glide JS Slider
 * @description Slider for Carousels
 */
import Glide from "@glidejs/glide";

const slider = () => {
	const slider = document.querySelectorAll(".glide");
	if (slider.length) {
		console.log(slider);

		slider.forEach(function (item) {
			new Glide(item, {
				autoplay: 5000,
				perView: 1,
				gap: 0,
				throttle: 40,
				bound: true,
				rewind: false,
			}).mount();
		});
	}
};

export { slider };
