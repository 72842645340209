export function initVideo() {
	const videoBlocks = document.querySelectorAll("[data-video]");
	videoBlocks.forEach(function (videoBlock) {
		const videoPlaceholder = videoBlock.querySelector("[data-video-poster]");
		videoPlaceholder.addEventListener("click", function () {
			this.classList.add("hidden");
			const video = videoBlock.querySelector("[data-video-frame]");
			const videoButton = videoBlock.querySelector("button");
			video.classList.add("block");
			video.classList.remove("hidden");
			videoButton.click();
		});
	});
}
