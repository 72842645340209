export function showElement(element) {
	console.log(element.previousElementSibling.querySelector("span"));

	element.previousElementSibling.setAttribute("aria-expanded", true);
	element.previousElementSibling.querySelector("span").classList.add("transform", "rotate-180");
	element.classList.add("block");
	element.style.maxHeight = element.scrollHeight + "px";
}

export function hideElement(element) {
	element.previousElementSibling.setAttribute("aria-expanded", false);
	element.previousElementSibling.querySelector("span").classList.remove("transform", "rotate-180");
	element.classList.remove("block");
	element.style.maxHeight = null;
}

export function toggleElement(element) {
	if (element.classList.contains("block")) {
		hideElement(element);
		return;
	}

	showElement(element);
}
